<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import ScoreModal from '@/components/ScoreModal'
import StyleModal from '@/components/StyleModal'
import { getAction, postAction } from '@/command/netTool'

export default {
  name: 'hotel-homestay',
  data() {
    return {
      ...api.command.getState(),
      // 评分设置弹窗
      showScoreModal: false,
      // 风格设置弹窗
      showStyleModal: false,
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/goods/farmRoom/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '房间名称',
          key: 'productName',
          type: 'input',
        },
        {
          name: '店铺名称',
          key: 'mainBodyName',
          type: 'input',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'productName',
          title: '房型名称',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-order-item">
                <img src={records.bannerUrl} />
                <div>{text}</div>
              </div>
            )
          },
          sorter: (a, b) => a.productName.length - b.productName.length,
        },
        {
          dataIndex: 'linkShopName',
          title: '店铺名称',
          align: 'left',
          sorter: (a, b) => a.linkShopName.length - b.linkShopName.length,
        },
        {
          dataIndex: 'hotelName',
          title: '酒店',
          align: 'left',
          sorter: (a, b) => a.hotelName.length - b.hotelName.length,
        },
        {
          dataIndex: 'roomName',
          title: '房间',
          align: 'left',
          sorter: (a, b) => a.roomName.length - b.roomName.length,
        },
        // {
        //   dataIndex: 'salePrice',
        //   title: '现价',
        //   align: 'left',
        //   customRender: function(text, records) {
        //     return records.salePrice == null ? '' : <div>{'￥' + records.salePrice}</div>
        //   },
        //   sorter: (a, b) => a.mainBodyName.length - b.mainBodyName.length
        // },
        {
          dataIndex: 'upDown',
          title: '状态',
          width: '90px',
          type: 'badge',
          align: 'center',
          onExport: (records) => {
            return ['上架', '下架'][records]
          },
          filters: [
            {
              text: '上架',
              value: '0',
            },
            {
              text: '下架',
              value: '1',
            },
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          fixed: 'right',
          typeData: ({ records }) => {
            return [
              {
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/goods/farmRoom/${records.upDown == 0 ? 'down' : 'up'}?id=${records.id}`,
                  })
                },
              },
              {
                name: '房间管理',
                onClick: () =>
                  this.$router.push(`/commodityManagement/roomManage?roomId=${records.id}&hotelId=${records.hotelId}`),
              },
              {
                name: '编辑',
                onClick: () => this.$router.push(`/commodityManagement/hotelHomestayDetail?id=${records.id}`),
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/goods/farmRoom?id=${records.id}`,
                  }),
              },
            ]
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/commodityManagement/hotelHomestayDetail'),
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.del.call(this, {
                  url: '/goods/farmRoom/deleteBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量上架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/goods/farmRoom/upBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量下架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/goods/farmRoom/downBatch',
                  params: { idList },
                })
              },
            },
          ],
        },
        {
          name: '酒店民宿管理',
          type: 'primary',
          onClick: () => this.$router.push('/commodityManagement/hotelHomestayManage'),
        },
        {
          name: '评分设置',
          type: 'primary',
          ghost: true,
          onClick: () => {
            this.showScoreModal = true
          },
        },
        {
          name: '风格样式',
          type: 'primary',
          ghost: true,
          onClick: () => {
            this.showStyleModal = true
          },
        },
      ]
    },
    renderModal() {
      return (
        <div>
          <ScoreModal
            getDataAPI={() => {
              return getAction('/config/farmConfigHome/detail', {}, '/operation').then((res) => {
                const data = res.data

                return {
                  ...data,
                  openScore: data.hotelScoreState == '1',
                }
              })
            }}
            updateDataAPI={(params) => {
              const { openScore } = params

              return postAction(
                '/config/farmConfigHome/update',
                { ...params, hotelScoreState: openScore ? '1' : '0' },
                '/operation'
              )
            }}
            v-model={this.showScoreModal}
          />
        </div>
      )
    },
    renderStyleModal() {
      return (
        <div>
            <StyleModal
            getDataAPI={() => {
              return getAction('/farmGoodsStyleConfig/getDetailByFromType', { fromType: '13' }, '/operation').then(
                (res) => {
                  const data = res.data || { img: '', fromType: '13' }
                  return {
                    ...data,
                  }
                }
              )
            }}
            updateDataAPI={(params) => {
              return postAction('/farmGoodsStyleConfig/save', { ...params }, '/operation')
            }}
            v-model={this.showStyleModal}
          />
        </div>
      )
    },
  },
  render() {
    return (
      <div>
        <TemplateHeaderTable
          headerData={this.getHeader()}
          tableColumns={this.getColumns()}
          button={this.getButton()}
          records={this.records}
        />
        {this.renderModal()}
        {this.renderStyleModal()}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15%;
  }
}
</style>
